import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { baseURL } from "../../../constants";
import { convertArea, lengthunits, units } from "../../../utils/units";
import { showToast } from "../../../utils/showToast";

const PlotCard = ({ plotDeatils, setBulkPlotDetails, index }) => {

  const [isCustomAccess, setIsCustomAccess] = useState(
    useState(
      ["6 Meters", "9 Meters", "12 Meters", "18 Meters", "24 Meters"].includes(
        plotDeatils[index].access_road
      )
        ? plotDeatils[index].access_road
        : "Other"
    )
  );


  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    plotDeatils[index]?.selectedOption || ""
  );
  useEffect(() => {
    setIsCustomAccess(
      ["6 Meters", "9 Meters", "12 Meters", "18 Meters", "24 Meters"].includes(
        plotDeatils[index].access_road
      )
        ? plotDeatils[index].access_road
        : "Other"
    );
  }, [plotDeatils[index]]);




  console.log("plotDeatils",plotDeatils)
  useEffect(() => {
    setSelectedOption(plotDeatils[index]?.selectedOption || "");
  }, [plotDeatils, index]);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setBulkPlotDetails((prevState) => {
      const tempArray = [...prevState];
      tempArray[index]["selectedOption"] = option;
      // Clear data when changing the dropdown option
      tempArray[index]["area"] = "";
      tempArray[index]["area_unit"] = "";
      tempArray[index]["access_road"] = "";
      tempArray[index]["rate"] = {
        rate: "",
        lumpsum: "",
        from: "",
        to: "",
        noquote: false,
        unit: "",
      };
      return tempArray;
    });
    setDropdownOpen(false); // Close the dropdown after selecting an option
  };

  return (
    <div
      className={`px-[16px] py-[20px] ${
        index !== 0 && "border-t-[1px] border-[#ECECEC]"
      }`}
    >
      <div className="flex justify-between items-center">
        <input
          onChange={(e) => {
            setBulkPlotDetails((prevState) => {
              let tempArray = [...prevState];
              tempArray[index]["plot_name"] = e.target.value;
              return [...tempArray];
            });
          }}
          placeholder="Plot Name"
          value={plotDeatils[index]?.plot_name}
          className="text-[20px] font-medium text-[#2B2B2B] rounded-regular border-[1px] border-[#D0D5DD] outline-none py-[12px] px-[16px]"
        ></input>
        <Icon
          icon="mdi:close"
          width={24}
          className="cursor-pointer"
          onClick={() => {
            if (plotDeatils.length > 2) {
              setBulkPlotDetails((prevState) => {
                let tempArray = [...prevState];
                console.log(tempArray);
                tempArray.splice(index, 1);
                console.log(tempArray);

                return [...tempArray];
              });
            }
          }}
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-x-[32px] gap-y-[20px] mt-[12px]">

      <div className="">
        <label
          htmlFor="select"
          className="block text-sm font-medium text-gray-700"
        >
          Please select one option 
          <span className="text-[#C62F39]"> *</span>
        </label>
        <div className="relative mt-1">
          <div className="w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14] flex ">
            <input
              value={selectedOption || "Please Select a Property Rate"}
              readOnly
              className="w-full rounded-regular px-4 text-sm text-gray-900 focus:outline-none"
              type="text"
              onClick={() => setDropdownOpen(!dropdownOpen)}
            />
            <button
              onClick={() => setDropdownOpen(!dropdownOpen)}
              className="flex items-center justify-center px-2 text-sm text-gray-800 hover:bg-gray-50"
            >
              <svg
                className="w-6 h-6 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 24"
              >
                <path d="M6 9l6 6 6-6"></path>
              </svg>
            </button>
          </div>
          {dropdownOpen && (
            <div className="absolute z-10 mt-1 w-full rounded-md bg-white shadow-lg border border-gray-200">
              {[
                "Selling Rate per unit",
                "Lump sum rate of Property",
                "Price range for Property",
                "Give price on request",
              ].map((option, index) => (
                <div key={index} className="cursor-pointer hover:bg-gray-100">
                  <div
                    className="px-4 py-2 text-sm text-gray-900"
                    onClick={() => handleOptionSelect(option)}
                  >
                    {option}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>


      {selectedOption === "Selling Rate per unit" &&
      <>
        <div className="flex flex-col w-full">
          <label
            htmlFor={`plotarea_${index}`}
            className="text-[#344054] text-[14px] font-medium"
          >
            Area
            {/* <span className="text-[#C62F39]"> *</span> */}
          </label>
          <div className="flex items-end gap-[12px]">
            <input
              id={`plotarea_${index}`}
              value={plotDeatils[index].area}
              onChange={(e) => {
                e.target.classList.remove("!border-[#C62F39]");

                var lumpsum = "";
                if (plotDeatils[index].area !== "") {
                  lumpsum =
                    convertArea(
                      plotDeatils[index].rate?.rate,
                      plotDeatils[index].area_unit,
                      plotDeatils[index].rate?.unit
                    ) * e.target.value;
                }
                setBulkPlotDetails((prevState) => {
                  let tempArray = [...prevState];
                  tempArray[index]["area"] = e.target.value;
                  tempArray[index]["rate"]["noquote"]
                    ? (tempArray[index]["rate"]["noquote"] = true)
                    : (tempArray[index]["rate"]["lumpsum"] = lumpsum);

                  return [...tempArray];
                });
              }}
              onKeyDown={(e) => {
                // Allow backspace, delete, and arrow keys
                if (
                  e.key === 'Backspace' ||
                  e.key === 'Delete' ||
                  e.key === 'ArrowLeft' ||
                  e.key === 'ArrowRight'
                ) {
                  return;
                }
            
                // Prevent non-numeric input and exclude 'e' and 'E'
                if (!/[\d+-.]/.test(e.key)) {
                  e.preventDefault();
                }
              }}
              placeholder="Area"
              type="number"
              className="w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14]"
              min="0" step="1"
            />
            <select
              value={plotDeatils[index].area_unit}
              onChange={(e) => {
                e.target.classList.remove("!border-[#C62F39]");

                var lumpsum = "";
                if (plotDeatils[index].area !== "") {
                  lumpsum =
                    convertArea(
                      plotDeatils[index].rate?.rate,
                      e.target.value,
                      plotDeatils[index].rate?.unit
                    ) * plotDeatils[index].area;
                }

                setBulkPlotDetails((prevState) => {
                  let tempArray = [...prevState];
                  tempArray[index]["area_unit"] = e.target.value;
                  tempArray[index]["rate"]["noquote"]
                    ? (tempArray[index]["rate"]["noquote"] = true)
                    : (tempArray[index]["rate"]["lumpsum"] = lumpsum);
                  return [...tempArray];
                });
              }}
              
              placeholder=""
              type="text"
              className={`max-w-[180px] mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14]`}
            >
              {units.map((unit) => (
                <option value={unit.value}>{unit.label}</option>
              ))}
              {/* <option value="m2" selected>
                Sq. Mt.
              </option>
              <option value="ft2">Sq. Ft.</option>
              <option value="g">Guntha</option>
              <option value="ac">Acre</option>
              <option value="ha">Hectare</option> */}
            </select>
          </div>
        </div>
        <div className="flex flex-col w-full">
          <label
            htmlFor={`plotrate_${index}`}
            className="text-[#344054] text-[14px] font-medium"
          >
            Expected Selling Price
            {/* <span className="text-[#C62F39]"> *</span> */}
          </label>
          <div className="flex items-end gap-[12px]">
            <input
              disabled={
                plotDeatils[index].rate.noquote === "true" ||
                plotDeatils[index].rate.noquote === true ||
                plotDeatils[index].rate.from !== "" ||
                plotDeatils[index].rate.to !== ""
              }
              value={plotDeatils[index].rate?.rate}
              onChange={(e) => {
                //e.target.classList.remove("!border-[#C62F39]");

                var lumpsum = "";
                if (plotDeatils[index].area !== "") {
                  lumpsum =
                    convertArea(
                      e.target.value,
                      plotDeatils[index].area_unit,
                      plotDeatils[index].rate?.unit
                    ) * plotDeatils[index].area;
                }

                setBulkPlotDetails((prevState) => {
                  let tempArray = [...prevState];
                  tempArray[index]["rate"]["rate"] = e.target.value;

                  tempArray[index]["rate"]["noquote"] = false;
                  tempArray[index]["rate"]["from"] = "";
                  tempArray[index]["rate"]["to"] = "";
                  tempArray[index]["rate"]["lumpsum"] =
                    lumpsum === 0 ? "" : lumpsum;
                  return [...tempArray];
                });
              }}
              placeholder="Selling price"
              id={`plotrate_${index}`}
              type="text"
              className="w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14] disabled:cursor-not-allowed"
            />
            <select
              disabled={
                plotDeatils[index].rate.noquote === "true" ||
                plotDeatils[index].rate.noquote === true ||
                plotDeatils[index].rate.from !== "" ||
                plotDeatils[index].rate.to !== ""
              }
              value={plotDeatils[index].rate?.unit}
              onChange={(e) => {
                e.target.classList.remove("!border-[#C62F39]");
                var lumpsum = "";
                if (plotDeatils[index].area !== "") {
                  lumpsum =
                    convertArea(
                      plotDeatils[index].rate?.rate,
                      plotDeatils[index].area_unit,
                      e.target.value
                    ) * plotDeatils[index].area;
                }

                setBulkPlotDetails((prevState) => {
                  let tempArray = [...prevState];
                  tempArray[index]["rate"]["unit"] = e.target.value;
                  tempArray[index]["rate"]["lumpsum"] =
                    lumpsum === 0 ? "" : lumpsum;
                  tempArray[index]["rate"]["noquote"] = false;
                  tempArray[index]["rate"]["from"] = "";
                  tempArray[index]["rate"]["to"] = "";
                  return [...tempArray];
                });
              }}
              placeholder=""
              type="text"
              className={`max-w-[180px] mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14] disabled:cursor-not-allowed`}
            >
              {units.map((unit) => (
                <option value={unit.value}>{unit.label}</option>
              ))}
              {/* <option value="m2" selected>
                Sq. Mt.
              </option>
              <option value="ft2">Sq. Ft.</option>
              <option value="g">Guntha</option>
              <option value="ac">Acre</option>
              <option value="ha">Hectare</option> */}
            </select>
          </div>
        </div>
        </>
}

{selectedOption === "Lump sum rate of Property" && 
        <div className="flex flex-col w-full">
          <label
            htmlFor={`plotlumpsum_${index}`}
            className="text-[#344054] text-[14px] font-medium"
          >
            Selling Price on Lump Sum Basis
            {/* <span className="text-[#C62F39]"> *</span> */}
          </label>
          <input
            disabled={
              plotDeatils[index].rate.noquote === "true" ||
              plotDeatils[index].rate.noquote === true ||
              plotDeatils[index].rate.from !== "" ||
              plotDeatils[index].rate.to !== ""
            }
            id={`plotlumpsum_${index}`}
            value={plotDeatils[index].rate?.lumpsum}
            onChange={(e) => {
              e.target.classList.remove("!border-[#C62F39]");
              var lumpsum = "";
              if (plotDeatils[index].area !== "") {
                lumpsum =
                  convertArea(
                    e.target.value,
                    plotDeatils[index].rate.unit,
                    plotDeatils[index].area_unit
                  ) / plotDeatils[index].area;
              }

              setBulkPlotDetails((prevState) => {
                let tempArray = [...prevState];
                tempArray[index]["rate"]["lumpsum"] = e.target.value;
                tempArray[index]["rate"]["rate"] =
                  lumpsum === 0
                    ? ""
                    : isNaN(Number(lumpsum))
                    ? ""
                    : Number(lumpsum).toFixed(2);
                tempArray[index]["rate"]["noquote"] = false;
                tempArray[index]["rate"]["from"] = "";
                tempArray[index]["rate"]["to"] = "";
                return [...tempArray];
              });
            }}
            placeholder="Expected Selling Price"
            type="text"
            className="w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14] disabled:cursor-not-allowed"
          />
        </div>
}

{selectedOption === "Price range for Property" && 
        <div className="flex justify-evenly gap-[8px]">
          <div className="flex flex-col w-full">
            <label
              htmlFor="lumpsum"
              className="text-[#344054] text-[14px] font-medium"
            >
              Min Price
              {/* <span className="text-[#C62F39]"> *</span> */}
            </label>
            <input
              disabled={
                plotDeatils[index].rate.noquote === "true" ||
                plotDeatils[index].rate.noquote === true ||
                plotDeatils[index].rate.lumpsum !== ""
              }
              value={plotDeatils[index].rate.from}
              onChange={(e) => {
                e.target.classList.remove("!border-[#C62F39]");

                setBulkPlotDetails((prevState) => {
                  let tempArray = [...prevState];
                  tempArray[index]["rate"]["rate"] = "";
                  tempArray[index]["rate"]["unit"] = "ft2";
                  tempArray[index]["rate"]["lumpsum"] = "";
                  tempArray[index]["rate"]["noquote"] = false;
                  tempArray[index]["rate"]["from"] = e.target.value;
                  return [...tempArray];
                });
              }}
              placeholder="Min Price"
              id="lumpsum"
              type="number"
              className="w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14] disabled:cursor-not-allowed"
            />
          </div>
          <div className="flex flex-col w-full">
            <label
              htmlFor="lumpsum"
              className="text-[#344054] text-[14px] font-medium"
            >
              Max Price
              {/* <span className="text-[#C62F39]"> *</span> */}
            </label>
            <input
              disabled={
                plotDeatils[index].rate.noquote === "true" ||
                plotDeatils[index].rate.noquote === true ||
                plotDeatils[index].rate.lumpsum !== ""
              }
              value={plotDeatils[index].rate.to}
              onChange={(e) => {
                e.target.classList.remove("!border-[#C62F39]");

                setBulkPlotDetails((prevState) => {
                  let tempArray = [...prevState];
                  tempArray[index]["rate"]["rate"] = "";
                  tempArray[index]["rate"]["unit"] = "ft2";
                  tempArray[index]["rate"]["lumpsum"] = "";
                  tempArray[index]["rate"]["noquote"] = false;
                  tempArray[index]["rate"]["to"] = e.target.value;
                  return [...tempArray];
                });
              }}
              placeholder="Max Price"
              id="lumpsum"
              type="number"
              className="w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14] disabled:cursor-not-allowed"
            />
          </div>
        </div>
}

{selectedOption === "Give price on request" && 

        <div className="flex w-full items-center">
          <input
            disabled={
              plotDeatils[index].rate.lumpsum !== "" ||
              plotDeatils[index].rate.from !== "" ||
              plotDeatils[index].rate.to !== ""
            }
            checked={plotDeatils[index].rate?.noquote}
            onChange={() => {
              setBulkPlotDetails((prevState) => {
                let tempArray = [...prevState];

                tempArray[index]["rate"]["rate"] = "";
                tempArray[index]["rate"]["unit"] = "ft2";
                tempArray[index]["rate"]["lumpsum"] = "";
                tempArray[index]["rate"]["from"] = "";
                tempArray[index]["rate"]["to"] = "";
                tempArray[index]["rate"]["noquote"] =
                  !tempArray[index]["rate"].noquote;
                return [...tempArray];
              });
            }}
            type="checkbox"
            id={`noquote__${index}`}
            className="cursor-pointer disabled:cursor-not-allowed"
          />{" "}
          <label
            htmlFor={`noquote__${index}`}
            className={`ml-[11px] text-[14px] text-labelText font-medium cursor-pointer ${
              (plotDeatils[index].rate.lumpsum !== "" ||
                plotDeatils[index].rate.from !== "" ||
                plotDeatils[index].rate.to !== "") &&
              "!cursor-not-allowed"
            }`}
          >
            Give Price on Buyers request
          </label>
        </div>

          }

      {/* {selectedOption === "Selling Rate per unit" && (
        <div className="flex mt-[24px]">
          <div className="flex flex-col w-full">
            <label
              htmlFor="rate"
              className="text-[#344054] text-[14px] font-medium"
            >
              Expected Selling Rate
              <span className="text-[#C62F39]"> *</span>
            </label>
            <input
              disabled={
                plotDetails.rate.noquote === "true" ||
                plotDetails.rate.noquote === true ||
                plotDetails.rate.from !== "" ||
                plotDetails.rate.to !== ""
              }
              value={plotDetails.rate.rate}
              onChange={(e) => {
                e.target.classList.remove("!border-[#C62F39]");
                var lumpsum = "";
                if (plotDetails.area !== "") {
                  lumpsum =
                    convertArea(
                      e.target.value,
                      plotDetails.area_unit,
                      plotDetails.rate?.unit
                    ) * plotDetails.area;
                }

                setPlotDetails((prevState) => {
                  let rate = { ...prevState.rate };
                  rate["rate"] = e.target.value;

                  rate["noquote"] = false;
                  rate["from"] = "";
                  rate["to"] = "";
                  rate["lumpsum"] = lumpsum === 0 ? "" : lumpsum;
                  return { ...prevState, rate };
                });
              }}
              placeholder="Enter your expected selling rate here e.g. 2000"
              id="rate"
              type="number"
              className="w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14] disabled:cursor-not-allowed"
            />
          </div>
          <div className="flex items-end gap-[16px]">
            <div className="py-[12px] px-[4px] text-[16px]">/</div>
            <div className="flex flex-col">
              <label
                htmlFor="rateunit"
                className="text-[#344054] text-[14px] font-medium"
              >
                Area Unit
                <span className="text-[#C62F39]"> *</span>
              </label>
              <select
                disabled={
                  plotDetails.rate.noquote === "true" ||
                  plotDetails.rate.noquote === true ||
                  plotDetails.rate.from !== "" ||
                  plotDetails.rate.to !== ""
                }
                value={plotDetails.rate.unit}
                onChange={(e) => {
                  e.target.classList.remove("!border-[#C62F39]");

                  var lumpsum = "";
                  if (plotDetails.area !== "") {
                    lumpsum =
                      convertArea(
                        plotDetails.rate?.rate,
                        plotDetails.area_unit,
                        e.target.value
                      ) * plotDetails.area;
                  }

                  setPlotDetails((prevState) => {
                    let rate = { ...prevState.rate };
                    rate["unit"] = e.target.value;
                    rate["lumpsum"] = lumpsum === 0 ? "" : lumpsum;
                    rate["noquote"] = false;
                    rate["from"] = "";
                    rate["to"] = "";
                    return { ...prevState, rate };
                  });
                }}
                placeholder="Agricultural/commercial/Residential"
                id="rateunit"
                type="number"
                className={`max-w-[180px] mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14]`}
              >
                {units.map((unit) => (
                  <option value={unit.value}>{unit.label}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
      )}

      {selectedOption === "Lump sum rate of Property" && (
        <div className="flex flex-col w-full mt-[24px]">
          <label
            htmlFor="lumpsum"
            className="text-[#344054] text-[14px] font-medium"
          >
            Expected Selling Price on Lump Sum Basis
            <span className="text-[#C62F39]"> *</span>
          </label>
          <input
            disabled={
              plotDetails.rate.noquote === "true" ||
              plotDetails.rate.noquote === true ||
              plotDetails.rate.from !== "" ||
              plotDetails.rate.to !== ""
            }
            value={plotDetails.rate.lumpsum}
            onChange={(e) => {
              e.target.classList.remove("!border-[#C62F39]");
              var lumpsum = "";
              if (plotDetails.area !== "") {
                lumpsum =
                  convertArea(
                    e.target.value,
                    plotDetails.rate.unit,
                    plotDetails.area_unit
                  ) / plotDetails.area;
              }

              setPlotDetails((prevState) => {
                let rate = { ...prevState.rate };
                rate["rate"] =
                  lumpsum === 0
                    ? ""
                    : isNaN(Number(lumpsum))
                    ? ""
                    : Number(lumpsum)?.toFixed(2);
                rate["lumpsum"] = e.target.value;
                rate["noquote"] = false;
                rate["from"] = "";
                rate["to"] = "";
                return { ...prevState, rate };
              });
            }}
            placeholder="Expected Selling Price"
            id="lumpsum"
            type="number"
            className="w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14] disabled:cursor-not-allowed"
          />
        </div>
      )}

      {selectedOption === "Price range for Property" && (
        <>
          <h6 className=" mt-[24px] text-[#344054] text-[14px] font-medium">
            Property Selling Rate Range
          </h6>
          <div className="relative">
            <div className="relative  mt-[10px]">
              <div className="flex justify-evenly gap-[8px] w-full">
                <div className="flex flex-col w-full">
                  <label
                    htmlFor="lumpsum"
                    className="text-[#344054] text-[14px] font-medium"
                  >
                    Min Price
                    <span className="text-[#C62F39]"> *</span>
                  </label>
                  <input
                    disabled={
                      plotDetails.rate.noquote === "true" ||
                      plotDetails.rate.noquote === true ||
                      plotDetails.rate.lumpsum !== ""
                    }
                    value={plotDetails.rate.from}
                    onChange={(e) => {
                      e.target.classList.remove("!border-[#C62F39]");

                      setPlotDetails((prevState) => {
                        let rate = { ...prevState.rate };

                        rate["rate"] = "";
                        rate["unit"] = "ft2";
                        rate["lumpsum"] = "";
                        rate["noquote"] = false;
                        rate["from"] = e.target.value;
                        return { ...prevState, rate };
                      });
                    }}
                    placeholder="Min Price"
                    id="lumpsum"
                    type="number"
                    className="w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14] disabled:cursor-not-allowed"
                  />
                </div>
                <div className="flex flex-col w-full">
                  <label
                    htmlFor="lumpsum"
                    className="text-[#344054] text-[14px] font-medium"
                  >
                    Max Price
                    <span className="text-[#C62F39]"> *</span>
                  </label>
                  <input
                    disabled={
                      plotDetails.rate.noquote === "true" ||
                      plotDetails.rate.noquote === true ||
                      plotDetails.rate.lumpsum !== ""
                    }
                    value={plotDetails.rate.to}
                    onChange={(e) => {
                      e.target.classList.remove("!border-[#C62F39]");

                      setPlotDetails((prevState) => {
                        let rate = { ...prevState.rate };
                        rate["rate"] = "";
                        rate["unit"] = "ft2";
                        rate["lumpsum"] = "";
                        rate["noquote"] = false;
                        rate["to"] = e.target.value;
                        return { ...prevState, rate };
                      });
                    }}
                    placeholder="Max Price"
                    id="lumpsum"
                    type="number"
                    className="w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14] disabled:cursor-not-allowed"
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {selectedOption === "Give price on request" && (
        <>
          <h6 className="mt-[24px] text-[#344054] text-[14px] font-medium">
            Give Price on Buyers request
          </h6>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-[16px] mt-[24px]">
            <div className="flex w-full items-center">
              <input
                disabled={
                  plotDetails.rate.lumpsum !== "" ||
                  plotDetails.rate.from !== "" ||
                  plotDetails.rate.to !== ""
                }
                checked={
                  plotDetails.rate?.noquote === true ||
                  plotDetails.rate?.noquote === "true"
                }
                onChange={() => {
                  setPlotDetails((prevState) => {
                    let rate = { ...prevState.rate };

                    rate["rate"] = "";
                    rate["unit"] = "ft2";
                    rate["lumpsum"] = "";
                    rate["from"] = "";
                    rate["to"] = "";
                    rate["noquote"] = !rate.noquote;
                    return { ...prevState, rate };
                  });
                }}
                type="checkbox"
                id="noquote"
                className="cursor-pointer disabled:!cursor-not-allowed"
              />{" "}
              <label
                htmlFor="noquote"
                className={`ml-[11px] text-[14px] text-labelText font-medium cursor-pointer ${
                  (plotDetails.rate.lumpsum !== "" ||
                    plotDetails.rate.from !== "" ||
                    plotDetails.rate.to !== "") &&
                  "!cursor-not-allowed"
                }`}
              >
                Give Price on Buyers request
              </label>
            </div>
          </div>
        </>
      )} */}

{/* **************** Above Dropdown ***************** */}
        <div className="flex flex-col w-full">
          <label
            htmlFor={`plotaccessroad_${index}`}
            className="text-[#344054] text-[14px] font-medium"
          >
            Property Access Road
            {/* <span className="text-[#C62F39]"> *</span> */}
          </label>
          <select
            value={isCustomAccess}
            onChange={(e) => {
              e.target.classList.remove("!border-[#C62F39]");

              if (e.target.value === "Other") {
                setIsCustomAccess(e.target.value);
                setBulkPlotDetails((prevState) => {
                  let tempArray = [...prevState];
                  tempArray[index]["access_road"] = "";
                  return [...tempArray];
                });
              } else {
                setIsCustomAccess(e.target.value);
                setBulkPlotDetails((prevState) => {
                  let tempArray = [...prevState];
                  tempArray[index]["access_road"] = e.target.value;
                  return [...tempArray];
                });
              }
            }}
            placeholder="Select the distance from access road"
            id={`plotaccessroad_${index}`}
            type="text"
            className={`w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] ${
              plotDeatils[index].access_road === ""
                ? "text-[#C7C7C7]"
                : "text-[#000E14]"
            }`}
          >
            <option className="hidden" value="" selected>
              Select the distance from access road
            </option>
            <option className="text-[#000E14]" value="6 Meters">
              6 Meters
            </option>
            <option className="text-[#000E14]" value="9 Meters">
              9 Meters
            </option>
            <option className="text-[#000E14]" value="12 Meters">
              12 Meters
            </option>
            <option className="text-[#000E14]" value="18 Meters">
              18 Meters
            </option>
            <option className="text-[#000E14]" value="24 Meters">
              24 Meters
            </option>
            <option className="text-[#000E14]" value="36 Meters">
              36 Meters
            </option>
            <option className="text-[#000E14]" value="Other">
              Other
            </option>
          </select>
        </div>

        {isCustomAccess === "Other" && (
          <div className="flex flex-col w-full">
            <label
              htmlFor="customaccess"
              className="text-[#344054] text-[14px] font-medium"
            >
              Type Access Road distance
              {/* <span className="text-[#C62F39]"> *</span> */}
            </label>
            <input
              value={plotDeatils[index].access_road}
              onChange={(e) => {
                e.target.classList.remove("!border-[#C62F39]");

                setBulkPlotDetails((prevState) => {
                  let tempArray = [...prevState];
                  tempArray[index]["access_road"] = e.target.value;
                  return [...tempArray];
                });
              }}
              onKeyDown={(e) => {
                // Allow backspace, delete, and arrow keys
                if (
                  e.key === 'Backspace' ||
                  e.key === 'Delete' ||
                  e.key === 'ArrowLeft' ||
                  e.key === 'ArrowRight'
                ) {
                  return;
                }
            
                // Prevent non-numeric input and exclude 'e' and 'E'
                if (!/[\d+-.]/.test(e.key)) {
                  e.preventDefault();
                }
              }}
              placeholder="6 Meters"
              id="customaccess"
              type="number"
              className="w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14]"
            />
          </div>
        )}
        <div className="flex flex-col w-full">
          <label
            htmlFor={`plotfacing_${index}`}
            className="text-[#344054] text-[14px] font-medium"
          >
            Property Facing Direction
            {/* <span className="text-[#C62F39]"> *</span> */}
          </label>
          <select
            value={plotDeatils[index].facing}
            onChange={(e) => {
              e.target.classList.remove("!border-[#C62F39]");

              setBulkPlotDetails((prevState) => {
                let tempArray = [...prevState];
                tempArray[index]["facing"] = e.target.value;
                return [...tempArray];
              });
            }}
            placeholder="Select the direction"
            id={`plotfacing_${index}`}
            type="text"
            className={`w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] ${
              plotDeatils[index].facing === ""
                ? "text-[#C7C7C7]"
                : "text-[#000E14]"
            }`}
          >
            <option className="hidden" value="" selected>
              Select the direction
            </option>
            <option className="text-[#000E14]" value="north">
              North
            </option>
            <option className="text-[#000E14]" value="south">
              South
            </option>
            <option className="text-[#000E14]" value="east">
              East
            </option>
            <option className="text-[#000E14]" value="west">
              West
            </option>
            <option className="text-[#000E14]" value="north-east">
              North-East
            </option>
            <option className="text-[#000E14]" value="north-west">
              North–West
            </option>
            <option className="text-[#000E14]" value="south-east">
              South-East
            </option>
            <option className="text-[#000E14]" value="south-west">
              South-West
            </option>
          </select>
        </div>
        <div className="flex flex-col w-full">
          <label
            htmlFor={`plotavaliability_${index}`}
            className="text-[#344054] text-[14px] font-medium"
          >
            Availability
            {/* <span className="text-[#C62F39]"> *</span> */}
          </label>
          <select
            value={plotDeatils[index].availability}
            onChange={(e) => {
              e.target.classList.remove("!border-[#C62F39]");

              setBulkPlotDetails((prevState) => {
                let tempArray = [...prevState];
                tempArray[index]["availability"] = e.target.value;
                return [...tempArray];
              });
            }}
            placeholder="Availability"
            id={`plotavaliability_${index}`}
            type="text"
            className={`w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14]`}
          >
            <option className="hidden" value="" selected>
              Availability
            </option>
            <option className="text-[#000E14]" value="available">
              Available
            </option>
            <option className="text-[#000E14]" value="sold">
              Sold
            </option>
          </select>
        </div>
        <div className="flex flex-col w-full">
          <label
            htmlFor={`plotfront_${index}`}
            className="text-[#344054] text-[14px] font-medium"
          >
            Front
            {/* <span className="text-[#C62F39]"> *</span> */}
          </label>
          <input
            value={plotDeatils[index].front}
            onChange={(e) => {
              e.target.classList.remove("!border-[#C62F39]");

              setBulkPlotDetails((prevState) => {
                let tempArray = [...prevState];
                tempArray[index]["front"] = e.target.value;
                return [...tempArray];
              });
            }}
            placeholder="e.g. 10 Meter"
            id={`plotfront_${index}`}
            type="text"
            className="w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14]"
          />
        </div>
        <div className="flex flex-col w-full">
          <label
            htmlFor={`plotdepth_${index}`}
            className="text-[#344054] text-[14px] font-medium"
          >
            Depth
            {/* <span className="text-[#C62F39]"> *</span> */}
          </label>
          <input
            value={plotDeatils[index].depth}
            onChange={(e) => {
              e.target.classList.remove("!border-[#C62F39]");

              setBulkPlotDetails((prevState) => {
                let tempArray = [...prevState];
                tempArray[index]["depth"] = e.target.value;
                return [...tempArray];
              });
            }}
            placeholder="e.g. 10 Meter"
            id={`plotdepth_${index}`}
            type="text"
            className="w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14]"
          />
        </div>
        <div className="flex flex-col w-full">
          <label
            htmlFor="areaunit"
            className="text-[#344054] text-[14px] font-medium"
          >
            Front & Depth Unit
            {/* <span className="text-[#C62F39]"> *</span> */}
          </label>
          <select
            value={plotDeatils[index].front_unit}
            onChange={(e) => {
              e.target.classList.remove("!border-[#C62F39]");
              setBulkPlotDetails((prevState) => {
                let tempArray = [...prevState];
                tempArray[index]["front_unit"] = e.target.value;
                return [...tempArray];
              });
            }}
            placeholder="Front Depth Unit"
            id="areaunit"
            type="text"
            className={`mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14]`}
          >
            {lengthunits.map((unit) => (
              <option value={unit.value}>{unit.label}</option>
            ))}

          </select>
        </div>
        <div className="flex flex-col w-full">
          <label className="text-[#344054] text-[14px] font-medium">
            Property Highlights
            <span className="text-[12px]"> (Optional) </span>
          </label>
          <input
            placeholder="Add highlights"
            type="text"
            className="w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14]"
            onKeyDown={(e) => {
              e.target.classList.remove("!border-[#C62F39]");
              if (e.key === "Enter" && e.target.value.trim() !== "") {
                setBulkPlotDetails((prevState) => {
                  let tempArray = [...prevState];
                  let hightlights = tempArray[index]["highlights"];
                  tempArray[index]["highlights"] = [
                    ...hightlights,
                    e.target.value,
                  ];
                  e.target.value = "";
                  return [...tempArray];
                });
              }
            }}
          />
          <div className="flex gap-x-[12px] gap-y-[8px] flex-wrap mt-[8px]">
            {plotDeatils[index].highlights?.map((highlight, i) => (
              <div className="px-[12px] py-[4px] bg-[#ECFDF3] w-fit flex gap-[8px] items-center rounded-[16px]">
                <p className="text-[10px] font-bold text-[#027A48]">
                  {highlight}
                </p>
                <Icon
                  icon="mdi:close"
                  width={10}
                  color="#027A48"
                  className="cursor-pointer"
                  onClick={() => {
                    setBulkPlotDetails((prevState) => {
                      let tempArray = [...prevState];
                      let hightlights = [...tempArray[index]["highlights"]];
                      hightlights.splice(i, 1);
                      tempArray[index]["highlights"] = [...hightlights];
                      return [...tempArray];
                    });
                  }}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col w-full">
          <label className="text-[#344054] text-[14px] font-medium">
            Videos
          </label>
          <input
            id={`plotvideo_${index}}`}
            placeholder="Paste Youtube Video link"
            type="text"
            className="w-full mt-[8px] rounded-regular border-[1px] border-[#D0D5DD] outline-none px-[16px] py-[12px] text-[16px] text-[#000E14]"
            onKeyDown={(e) => {
              if (e.key === "Enter" && e.target.value.trim() !== "") {
                setBulkPlotDetails((prevState) => {
                  let tempArray = [...prevState];
                  let videos = tempArray[index]["videos"];
                  tempArray[index]["videos"] = [...videos, e.target.value];
                  e.target.value = "";
                  return [...tempArray];
                });
              }
            }}
          />
          <div className="flex gap-x-[12px] gap-y-[8px] flex-wrap mt-[8px]">
            {plotDeatils[index].videos?.map((video, i) => (
              <div className="px-[12px] py-[4px] bg-[#ECFDF3] w-fit flex gap-[8px] items-center rounded-[16px]">
                <p className="text-[10px] font-bold text-[#027A48]">{video}</p>
                <Icon
                  icon="mdi:close"
                  width={10}
                  color="#027A48"
                  className="cursor-pointer"
                  onClick={() => {
                    setBulkPlotDetails((prevState) => {
                      let tempArray = [...prevState];
                      let videos = [...tempArray[index]["videos"]];
                      videos.splice(i, 1);
                      tempArray[index]["videos"] = [...videos];
                      return [...tempArray];
                    });
                  }}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col w-full">
          <label className="text-[#344054] text-[14px] font-medium">
            Upload Image
          </label>
          <div className="flex items-center mt-[8px] gap-[16px]">
            <label className="w-full bg-[#F6F6F6] flex items-center px-[16px] py-[12px] rounded-regular border-dashed border-[1px] border-[#367DE7] cursor-pointer text-[#666666] gap-[8px] text-[16px]">
              <Icon icon="ic:outline-cloud-upload" width={24} />
              {document.file?.name ? document.file?.name : "Upload"}
              <input
                multiple
                accept="image/*"
                type="file"
                className="hidden"
                onChange={(e) => {
                  e.target.classList.remove("!border-[#C62F39]");
                  let containsLargeFile = false;
                  [...e.target.files]?.map((image) => {
                    if (image.size > 5242880) {
                      showToast("Image size should be less than 5MB", "error");
                      e.value = "";
                      containsLargeFile = true;
                    }
                    return false;
                  });
                  if (!containsLargeFile) {
                    setBulkPlotDetails((prevState) => {
                      let tempArray = [...prevState];
                      let prevImages = tempArray[index]["images"];
                      tempArray[index]["images"] = [
                        ...prevImages,
                        ...e.target.files,
                      ];
                      return [...tempArray];
                    });
                  }
                }}
              />
            </label>
          </div>
        </div>
        <div className="flex flex-wrap md:col-span-2 gap-[8px]">
          {plotDeatils[index].images?.map((image, i) => (
            <div className="w-[85px] h-[85px] rounded-regular relative">
              <button
                className="absolute right-[8px] top-[8px]"
                onClick={() => {
                  setBulkPlotDetails((prevState) => {
                    let tempArray = [...prevState];
                    tempArray[index]["images"].splice(i, 1);
                    return [...tempArray];
                  });
                }}
              >
                <Icon icon="mdi:close-circle" width={20} color="#fff" />
              </button>

              <img
                src={URL.createObjectURL(image)}
                alt=""
                className="w-[85px] h-[85px] rounded-regular"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PlotCard;
