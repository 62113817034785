import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import convert from "convert-units";
import { interested, saved } from "../../apis/apiClient";
import { updateUser } from "../../reducers/profile";
import { numDifferentiation } from "../../utils/sharedFunc";
import { showToast } from "../../utils/showToast";
import ActionButtons from "./ActionButtons";
import { convertArea, getUnitLabel, units } from "../../utils/units";
import {
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  TelegramIcon,
  WhatsappIcon,
  EmailIcon,
} from "react-share";
import { baseURL } from "../../constants";

const CustomOption = (props) => {
  const { data, innerRef, innerProps } = props;
  return (
    <div
      ref={innerRef}
      {...innerProps}
      className="px-[16px] py-[10px] hover:bg-[#F6F6F6] flex justify-between text-[#667085] text-[12px] sm:text-[16px] cursor-pointer"
    >
      <div className="flex gap-[8px] items-center">
        <span>{data.label}</span>
        {data.value.availability === "sold" && (
          <div
            className={`px-[8px] py-[3px] rounded-full text-[12px] md:text-[14px] font-smibold flex gap-[8px] items-center w-fit bg-[#FEF3F2] text-[#B42318]`}
          >
            <p>Sold</p>
          </div>
        )}
      </div>
      <span>
        {data.value.area} {data.value.area_unit}
        {/* 129.65 Sq.m X 13 Sq.m ({data.value.area} {data.value.area_unit}) */}
      </span>
    </div>
  );
};

const PlotMainDetails = ({
  plotDetails,
  setSelectedPlot,
  selectedPlot,
  handleViewBids,
}) => {
  const [isShareOpen, setIsShareOpen] = useState(false);
  const [area, setArea] = useState(0);
  const profile = useSelector((state) => state.profile);
  const dispatch = useDispatch();

  const handleSave = async () => {
    try {
      const response = await saved({
        plotId: plotDetails.id,
      });
      if (response.status === "success") {
        if (profile.userData?.saved.includes(plotDetails.id)) {
          showToast(`You have Unsaved ${plotDetails.puid} plot`, "success");
          const preUserData = profile.userData;
          const savedArr = [...preUserData.saved];

          const index = savedArr?.indexOf(plotDetails.id);
          if (index > -1) {
            // only splice array when item is found
            savedArr.splice(index, 1); // 2nd parameter means remove one item only
          }

          dispatch(
            updateUser({
              ...preUserData,
              saved: [...savedArr],
            })
          );
        } else {
          showToast(`You have Saved ${plotDetails.puid} plot`, "success");
          const preUserData = profile.userData;
          dispatch(
            updateUser({
              ...preUserData,
              saved: [...preUserData.saved, plotDetails.id],
            })
          );
        }
      } else {
        showToast(`Some error occured, Please try again later`, "error");
        console.log(response.status);
      }
    } catch (e) {
      // if (e.response?.status === 400) {
      //   showToast(`Please fill all details to list property`, "error");
      // } else {
      showToast(`Some error occured, Please try again later`, "error");
      // }
      console.log("error", e);
    }
  };

  useEffect(() => {
    setArea(convertArea(plotDetails.area, "ft2", plotDetails.area_unit));
    document.getElementsByTagName("body")[0].addEventListener("click", (e) => {
      if (e.target.tagName === "path" || e.target.tagName === "svg") {
        return false;
      }
      setIsShareOpen(false);
    });
    return () => {
      document
        .getElementsByTagName("body")[0]
        .removeEventListener("click", () => {});
    };
  }, [plotDetails]);

  const handleInterested = async () => {
    try {
      const response = await interested({
        plotId: plotDetails.id,
      });
      if (response.status === "success") {
        if (profile.userData?.interested.includes(plotDetails.id)) {
          showToast(
            `Removed your interest from plot ${plotDetails.puid}`,
            "success"
          );

          const preUserData = profile.userData;
          const interestedArr = [...preUserData.interested];

          const index = interestedArr?.indexOf(plotDetails.id);
          if (index > -1) {
            // only splice array when item is found
            interestedArr.splice(index, 1); // 2nd parameter means remove one item only
          }

          dispatch(
            updateUser({
              ...preUserData,
              interested: [...interestedArr],
            })
          );
        } else {
          showToast(
            `Added your interest in plot ${plotDetails.puid}`,
            "success"
          );
          const preUserData = profile.userData;
          dispatch(
            updateUser({
              ...preUserData,
              interested: [...preUserData.interested, plotDetails.id],
            })
          );
        }
      } else {
        showToast(`Some error occurred, please try again later`, "error");
      }
    } catch (e) {
      showToast(`Some error occurred, please try again later`, "error");
      console.log(e);
    }
  };

  return (
    <div className="w-full">
      <h6 className="text-[16px] text-[#666666] font-medium">
        PUID : {plotDetails.puid}
      </h6>
      <div className="flex mt-[4px] gap-[24px]">
        <div className="flex w-full gap-[24px]">
          <h1 className="text-[28px] sm:text-[32px] text-[#2B2B2B] font-bold max-w-[400px]">
            {plotDetails.title}
          </h1>
          {plotDetails.availability === "sold" && (
            <div
              className={`px-[12px] py-[8px] rounded-regular h-fit text-[12px] md:text-[14px] font-smibold flex gap-[8px] items-center w-fit bg-[#FEF3F2] text-[#B42318]`}
            >
              <p>Sold</p>
            </div>
          )}
        </div>

        {!(
          plotDetails.listed_by?.id === profile.userData?.id ||
          profile.userData?.role === "admin"
        ) &&
          (plotDetails.type === "single" || selectedPlot.value?.puid) &&
          (profile.userData?.saved?.includes(plotDetails.id) ? (
            <Icon
              onClick={handleSave}
              icon="material-symbols:bookmark-sharp"
              className="cursor-pointer"
              width="42"
              height="42"
              color="#1C1C1C"
            />
          ) : (
            <Icon
              onClick={handleSave}
              icon="material-symbols:bookmark-outline-sharp"
              className="cursor-pointer"
              width="42"
              height="42"
              color="#1C1C1C"
            />
          ))}

        <div className="relative">
          <Icon
            icon="material-symbols:share"
            width="42"
            height="42"
            color="#1C1C1C"
            className="cursor-pointer"
            onClick={() => {
              setIsShareOpen((prevState) => !prevState);
            }}
          />
          <div
            id="dropdown"
            className={`z-10  bg-[#efefef] rounded-regular divide-y gap-[8px] divide-gray-100 px-[12px] py-[8px] shadow-xl absolute flex transition duration-100 ease-in-out origin-top-left ${
              isShareOpen ? "scale-100" : "scale-0"
            }`}
          >
            <FacebookShareButton
              url={window.location.href}
              quote={plotDetails.title}
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>

            <TwitterShareButton
              url={window.location.href}
              title={plotDetails.title}
            >
              <TwitterIcon size={32} round />
            </TwitterShareButton>

            <TelegramShareButton
              url={window.location.href}
              title={plotDetails.title}
            >
              <TelegramIcon size={32} round />
            </TelegramShareButton>
            <WhatsappShareButton
              url={window.location.href}
              title={plotDetails.title}
              separator={`PUID: ${plotDetails.puid} Price: ${
                plotDetails.rate?.lumpsum !== ""
                  ? plotDetails.rate?.lumpsum
                  : plotDetails.rate?.from &&
                    plotDetails.rate?.from !== "" &&
                    plotDetails.rate?.to &&
                    plotDetails.rate?.to !== ""
                  ? `${numDifferentiation(
                      Number(plotDetails.rate.from)
                    )} - ${numDifferentiation(Number(plotDetails.rate.to))}`
                  : `${plotDetails.rate.rate} / ${getUnitLabel(
                      plotDetails.rate.unit
                    )}`
              } City: ${plotDetails.location.district} Visit here: `}
            >
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>

            <EmailShareButton
              url={window.location.href}
              subject={plotDetails.title}
              body="body"
              className="Demo__some-network__share-button"
            >
              <EmailIcon size={32} round />
            </EmailShareButton>
          </div>
        </div>
      </div>
      {/* <h6 className="text-[16px] text-[#666666] font-medium mt-[12px] flex items-center">
        Owner : {plotDetails.owner_name}
        {plotDetails.listed_by?.isVerified && (
          <Icon
            icon="material-symbols:verified"
            width={22}
            height={22}
            color="#58B455"
            className="ml-[12px]"
          />
        )}
      </h6> */}
      {/* <h6 className="text-[16px] text-[#666666] font-medium mt-[12px] flex items-center">
        Marketed by : {plotDetails.marketed_by}
        {plotDetails.listed_by?.isVerified && (
          <Icon
            icon="material-symbols:verified"
            width={22}
            height={22}
            color="#58B455"
            className="ml-[12px]"
          />
        )}
      </h6> */}
      {plotDetails.rera_number && plotDetails.rera_number?.trim() !== "" ? (
        <h6 className="text-[16px] text-[#666666] font-medium mt-[12px] flex items-center">
          RERA Registration Number: {plotDetails.rera_number}
          {plotDetails.listed_by?.isVerified && (
            <Icon
              icon="material-symbols:verified"
              width={22}
              height={22}
              color="#58B455"
              className="ml-[12px]"
            />
          )}
        </h6>
      ) : (
        ""
      )}
      <div className="shadow-md py-[16px] px-[24px] mt-[24px] rounded-regular">
        {plotDetails.type === "bulk" && (
          <>
            <p className="text-[#344054] text-[14px] font-medium">
              Select Plot
            </p>
            <div className="my-[8px]">
              <Select
                components={{ Option: CustomOption }}
                onChange={(data) => {
                  setSelectedPlot(data);
                }}
                value={selectedPlot}
                options={plotDetails.plots.map((plot, index) => {
                  return {
                    value: plot,
                    label: plot.plot_name,
                  };
                })}
                placeholder="Please select plot from the list"
                // classNames={}
                primaryColor="#000"
              />
            </div>
          </>
        )}
        {plotDetails.rate && (
          <div className="flex justify-between items-center">
            <div className="flex gap-[12px] text-[#000E14] text-[16px] sm:text-[24px] font-semibold items-center">
              <Icon icon="material-symbols:currency-rupee" />{" "}
              {plotDetails.rate?.noquote === "true" && (
                <span onClick={handleInterested}>Get Price on request </span>
              )}
              {plotDetails.rate?.lumpsum !== "" && (
                <span>
                  {numDifferentiation(Number(plotDetails.rate.lumpsum))}
                </span>
              )}
              {plotDetails.rate?.from &&
                plotDetails.rate?.from !== "" &&
                plotDetails.rate?.to &&
                plotDetails.rate?.to !== "" && (
                  <span>
                    {numDifferentiation(Number(plotDetails.rate.from))} -{" "}
                    {numDifferentiation(Number(plotDetails.rate.to))}
                  </span>
                )}
            </div>
            {plotDetails.rate.rate !== "" &&
              plotDetails.rate?.rate !== "null" && (
                <div className="flex gap-[4px] text-[#666666] text-[12px] md:text-[16px] font-medium items-center">
                  <Icon icon="material-symbols:currency-rupee" />
                  <span>
                    {plotDetails.rate.rate} /{" "}
                    {getUnitLabel(plotDetails.rate.unit)}
                  </span>
                </div>
              )}
          </div>
        )}
        <div className="flex flex-col sm:flex-row justify-between sm:items-end mt-[20px] gap-[16px]">
          <div className="flex flex-col gap-[20px]">
            {plotDetails.area && (
              <>
                <div className="flex gap-[20px] text-[#1C1C1C] text-[16px] items-center">
                  <Icon icon="material-symbols:crop" width={24} />{" "}
                  <span>Plot Area : {area}</span>{" "}
                  <select
                    className=" py-[10px] outline-none text-[14px] font-medium text-[#1C1C1C] font-medium bg-transparent border-none"
                    defaultValue={plotDetails.area_unit}
                    onChange={(e) => {
                      setArea(
                        convertArea(plotDetails.area, "ft2", e.target.value)
                      );
                    }}
                  >
                    {units.map((unit) => (
                      <option value={unit.value}>{unit.label}</option>
                    ))}
                    {/* <option value="m2">Square Meter</option>
                    <option value="g">Guntha</option>
                    <option value="ac">Acre</option>
                    <option value="ha">Hectare</option> */}
                  </select>{" "}
                </div>
                <div className="flex gap-[20px] text-[#1C1C1C] text-[16px] items-center">
                  <Icon icon="material-symbols:mode-standby" width={24} />
                  <span>
                    Survey No - {plotDetails.location?.survey_number}
                  </span>{" "}
                  <span>Plot No - {plotDetails.location?.plot_number}</span>{" "}
                </div>
              </>
            )}
            <div className="flex gap-[20px] text-[#1C1C1C] text-[16px] items-center">
              <Icon icon="material-symbols:location-on" width={24} />{" "}
              <span>
                {`${plotDetails.location?.landmark}, ${plotDetails.location?.village}, ${plotDetails.location?.taluka}, ${plotDetails.location?.district}, ${plotDetails.location?.pincode},
                      ${plotDetails.location?.state}`}
              </span>{" "}
            </div>
          </div>
          <button
            onClick={() => {
              document.getElementById("map").scrollIntoView();
            }}
            className="py-[8px] px-[12px] border-[1px] border-[#0259DB] text-[#0259DB] rounded-regular text-[14px] text-medium min-w-[120px]"
          >
            View on map
          </button>
        </div>
      </div>
      <div className="mt-[24px] flex justify-between items-center">
        <div className="px-[12px] py-[8px] bg-[#EAF2FF] rounded-regular text-[#0259DB] text-[12px] md:text-[16px] font-medium flex gap-[8px] items-center capitalize">
          {plotDetails.category} / {plotDetails.sub_category}
        </div>
        <div
          className="px-[12px] py-[8px] bg-[#EAF2FF] rounded-regular text-[#0259DB] text-[12px] md:text-[16px] font-medium flex gap-[8px] items-center cursor-pointer"
          onClick={handleViewBids}
        >
          {plotDetails.listed_by?.id === profile.userData?.id ||
          profile.userData?.role === "admin"
            ? "View Offers Placed"
            : "Offers Placed"}

          <span className="bg-[#CCDEF8] px-[6px] rounded-[4px] text-[#0247AF] font-medium text-[14px]">
            {plotDetails.bids?.length}
          </span>
        </div>
      </div>

      <ActionButtons plotDetails={plotDetails} selectedPlot={selectedPlot} />
    </div>
  );
};

export default PlotMainDetails;
